// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { mkConfig, generateCsv, download } from 'export-to-csv';
//import TablaCobranzas from "views/Grillas/CobranzasGrilla";

//////////nuevos
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { jsPDF } from 'jspdf';
import jsonToTable from 'json-to-table';

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import dayjs from "dayjs";
import GrillaEffect from "views/Grillas/GrillaEffect";
import DataLabel from "views/Cards/DataLabel";
registerLocale("es", es); // register it with the name you want

const theme = createTheme({
  palette: {}
});

function Cobranzas() {
  const defaultFecha = [
    {id: 'periodo_cobranza', value: dayjs().subtract(1, 'month').format('YYYY-MM'), col_ori: 'a.FactFec'}
  ];
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [filters, setFilters] = useState([...defaultFecha]);
  const textColor = useColorModeValue("gray.700", "white");
  //agregamos lagrilla
  const columnNames = {
    fecha_cobranza: 'F. Cobranza',
    periodo_cobranza: 'Pdo. Cobranza',
    dia_cobranza: 'Dia Cobranza',
    importe: 'Importe',
    periodo_vto: 'Pdo. Vto.',
    fecha_vto: 'F. Vto.',
    periodo_rec: 'Pdo. Rec.',
    tipo_cancelacion: 'Tipo Canc.',
    ente: 'Ente',
    punto_vta: 'Punto Vta.'
  };
  const columnNamesFPago = {
    periodo_cobranza: 'Pdo. Cobranza',
    importe: 'Importe',
    ente: 'Ente',
  };

  ///exporta csv
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const GenerarCsv = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/19`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();
      // console.log(" get consulta ", resulte);          
      // Convierte la respuesta a formato JSON
      const csv = generateCsv(csvConfig)(resulte);
      download(csvConfig)(csv);

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  ///exporta pdf 
  const GenerarPdf = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/19`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();

      const tabled = jsonToTable(resulte);
      let nombresCampos = tabled[0];
      let datos = tabled.shift();
      const doc = new jsPDF();

      doc.autoTable({
        head: [nombresCampos],
        body: tabled,
        margin: { top: 10 },
        styles: {
          minCellHeight: 4,
          halign: "left",
          valign: "center",
          fontSize: 4,
        },
      });
      doc.save('pdf-cobranzas.pdf');

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  //////////////
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody size='sm'>
        <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='22px'>
            <GridItem colSpan={2}>
              <Card minH='125px'>
                <CardBody px='2px'>
                  <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='5px'>
                    <DataLabel url={`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysextgrilla/?id=`+26} filters={filters} label={"TOTAL COBRADO"}/>
                    <DataLabel filters={filters} isMoney={false} countDateColumn={'periodo_cobranza'} label={"CANTIDAD DE DIAS"}/>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card minH='125px'>
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Flex align='center'>
                      <Button                      
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='green'
                        onClick={GenerarCsv}
                      >
                        Exportar CSV
                      </Button>
                    </Flex>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='red'
                        onClick={GenerarPdf}
                      >
                        Exportar PDF
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
          </Grid>
          <Card p='16px' my='24px'>
            <CardBody >
            <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Cobranzas</Tab>
                  <Tab>Formas de Pago</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <ThemeProvider theme={theme}>
                    <GrillaEffect 
                      data={data} updateData={setData} 
                      filters={filters} updateFilters={setFilters}
                      idRemoto={19}
                      colsHeaders={columnNames}/>
                  </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                  <TabPanel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <ThemeProvider theme={theme}>
                    <GrillaEffect 
                      data={data2} updateData={setData2} 
                      filters={filters} updateFilters={setFilters}
                      idRemoto={43}
                      colsHeaders={columnNamesFPago}/>
                  </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>

  );
}

export default Cobranzas;
