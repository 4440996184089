//import theme from "theme/theme.js";
import { useMemo, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Spinner,Stack } from '@chakra-ui/react'
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
const theme = createTheme({
  palette: {}
});

//var apiUrlx = '';
var codigo = '';
var idName = '';
var apiUrlx = '';
var campos ='';

////////////////////////////////////////////////////////
const ObjetosGrilla = ({ cru_codigo }) => {
  const [columns, setColumns] = useState([]); 
  codigo = cru_codigo;
  const [validationErrors, setValidationErrors] = useState({});  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const rest = await fetch(`http://localhost:3001/api/v1/cruddefinicion/` + codigo, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
        });
        const resulte = await rest.json();
        apiUrlx = resulte.cru_REACT_APP_API_URL;
        
        idName = resulte.cru_campo_pk;
        campos = resulte.cru_campos;                   
        // Define las columnas después de obtener los datos
        const newColumns = campos.map(campo => ({
          accessorKey: campo.accessorKey,
          header: campo.header,
          enableEditing: campo.enableEditing === "false" ? false : true,
          editVariant: campo.editVariant ,
          editSelectOptions: campo.editSelectOptions ,
          size:  campo.size ? parseInt(campo.size, 10) : undefined,

          muiEditTextFieldProps: {
            type: campo.type ? campo.type: undefined,
            required: campo.required === "false" ? false : true,
            error: !!validationErrors?.[campo.accessorKey],
            helperText: validationErrors?.[campo.accessorKey],          
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                [campo.accessorKey]: undefined,
              }),
          },

        }));       
        setColumns(newColumns);
                
      } catch (err) {
        console.error('Error de autenticación:', err.message);
      }
    };

    fetchData();
  }, [cru_codigo, validationErrors]);

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  //call READ hook
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers();
  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    //console.log('validacion valore del rol',values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    //console.log('valore del rol',values);
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {    
    if (window.confirm('Esta seguro de eliminar el registro?')) {      
      deleteUser(row.original[idName]);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
        color: 'error',
        children: 'Error al cargar Datos',
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Nuevo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h4">Modificar</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Editar">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {
          <Tooltip title="Baja">
            <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          //inicia los valores para un nuevo registro
          //table.setCreatingRow(
          // createRow(table, { obj_codigo:"",obj_descripcion:"",obj_estado : "HABILITADO",obj_etiqueta:"",obj_tipo:"MENU",
          //optionally pass in default values for the new row, useful for nested data or other complex scenarios               
          // }),
          //);
        }}
      >
        Nuevo
      </Button>
    ),
    tipo: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  //return <MaterialReactTable table={table} />;
  return apiUrlx !== '' ? (
    <MaterialReactTable table={table} />
  ) : (
    // Renderiza un spinner o indicador de carga aquí
   <div>
    <Spinner
    thickness='30px'   
    speed='0.65s'
    emptyColor='gray.200'
    color='blue.500'
    size='xl'
  />
   </div>             
  );
};

//CREATE hook (post new user to api)
function useCreateUser() {
  const queryClient = useQueryClient();

  //console.log("crea un nuevo  " );

  return useMutation({
    mutationFn: async (obj) => {
      //send api update request here
      try {
        // Realiza la solicitud a la API        
        const rest = await fetch(apiUrlx,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            },
            body: JSON.stringify(
              obj
            )
          })

        const resulte = await rest.json();        
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
   /* onMutate: (newUserInfo) => {
      queryClient.setQueryData(['objetos'], (prevUsers) => [
        ...prevUsers,
        {
          ...newUserInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    },*/

    onSettled: () => {     
      queryClient.invalidateQueries({ queryKey: ['objetos'] })     
    }, //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUsers() {
  return useQuery({
    queryKey: ['objetos', apiUrlx],
    queryFn: async () => {  
        try {
         // console.log(" apiUrl ", apiUrlx);
          if (!apiUrlx) {
            // Si apiUrlx no tiene un valor válido, retorna una promesa vacía
            return { data: [] };
           
          }  
          // Realiza la solicitud a la API         
          const rest = await fetch(apiUrlx,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token'),
              },
            })
           
          const resulte = await rest.json();         
          // Verifica si la solicitud fue exitosa (código de estado 200)      
          // Convierte la respuesta a formato JSON
          return resulte;
        } catch (error) {
          console.error(`Error en la solicitud: ${error.message}`);
          throw error;
        }    
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (obj) => {
      //send api update request here
      try {
        // Realiza la solicitud a la API
        const rest = await fetch(apiUrlx + '/' + obj[idName],
          {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            },
            body: JSON.stringify(
              obj
            )
          })

        const resulte = await rest.json();
        // console.log(" put usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo) => {
      queryClient.setQueryData(['objetos'], (prevUsers) =>
        prevUsers?.map((prevUser) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
        ),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['objetos'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (codigo) => {
      //send api update request here
      try {
        // Realiza la solicitud a la API       
        // console.log(" borar  usr_codigo ",usr_codigo);
        const rest = await fetch(apiUrlx + '/' + codigo,
          {
            method: "delete",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            },
          })
        const resulte = await rest.json();
        //console.log(" delete usuarios ",resulte);
        // Verifica si la solicitud fue exitosa (código de estado 200)      
        // Convierte la respuesta a formato JSON
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }

      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (userId) => {
      queryClient.setQueryData(['objetos'], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['objetos'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = ({ cru_codigo }) => {

  return (
    //Put this with your other react-query providers near root of your app  
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ObjetosGrilla
          cru_codigo={cru_codigo}
        />      
      </QueryClientProvider>
    </ThemeProvider>           
  );
}

export default ExampleWithProviders;

const validateRequired = (value) => !!value.length;
/*
function validateUser(user) {
  return {

    obj_descripcion: !validateRequired(user.obj_descripcion) ? 'campo Requerido' : '',
    obj_etiqueta: !validateRequired(user.obj_etiqueta) ? 'campo Requerido' : '',

  };
}

*/
function validateUser(user) {
  const errors = {};

  campos.forEach((campo) => {
    const { accessorKey, required } = campo;

    if (required && !validateRequired(user[accessorKey])) {
      errors[accessorKey] = 'Campo requerido';
    }
  });

  return errors;
}