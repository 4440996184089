// import
import React from 'react';
import Dashboard from "views/Dashboard/Dashboard";
import Cobranzas from "views/Dashboard/Cobranzas";
import FacturacionPuntual from "views/Dashboard/FacturacionPuntual";
import PagosEmitidos from "views/Dashboard/PagosEmitidos";
import FacturacionBatch from "views/Dashboard/FacturacionBatch";
import DeudaVencida from 'views/Dashboard/DeudaVencida';
import Usuarios from "views/Dashboard/Usuarios";
import Roles from "views/Dashboard/Roles";
import Objetos from "views/Dashboard/Objetos";
import Querys from "views/Dashboard/Querys";

import SignIn from "views/Pages/SignIn";
import SignUp from "views/Pages/SignUp";
import CambioCla from "views/Pages/CambioClave";

import ObjetosAbm from "views/Dashboard/ObjetosAbm";
import PruebaDesplegable from "views/Dashboard/PruebaDesplegable";


import {
  HomeIcon,
  StatsIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
} from "components/Icons/Icons";
import PresupuestoOperativo from 'views/Dashboard/PresupuestoOperativo';
import PresupuestoFinanciero from 'views/Dashboard/PresupuestoFinanciero';
import RecursosHumanos from 'views/Dashboard/RecursosHumanos';

var dashRoutes = [
  {
    path: "/dashboard",
    key: "dashboard",
    name: "Inicio",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/cobranzas",
    key: "cobranzas",
    name: "Cobranzas",
    icon: <StatsIcon color='inherit' />,
    component: Cobranzas,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/deudavencida",
    key: "deudavencida",
    name: "Deuda Vencida",
    icon: <StatsIcon color='inherit' />,
    component: DeudaVencida,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/pagos",
    key: "pagos",
    name: "Pagos Emitidos",
    icon: <StatsIcon color='inherit' />,
    component: PagosEmitidos,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/batch",
    key: "fbatch",
    name: "Facturación",
    icon: <StatsIcon color='inherit' />,
    component: FacturacionBatch,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/presupuesto_operativo",
    key: "fpstooperativo",
    name: "Cash Flow Ppto. Operativo",
    icon: <StatsIcon color='inherit' />,
    component: PresupuestoOperativo,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/presupuesto_financiero",
    key: "fpstofinanciero",
    name: "Cash Flow Ppto. Financiero",
    icon: <StatsIcon color='inherit' />,
    component: PresupuestoFinanciero,
    layout: "/admin",
    tipo: "MENU",
  },
  {
    path: "/recursos_humanos",
    key: "frrhh",
    name: "Recursos Humanos",
    icon: <StatsIcon color='inherit' />,
    component: RecursosHumanos,
    layout: "/admin",
    tipo: "MENU",
  },  
  {    
    name: "Configuracion",
    key: "configuracion",
    category: "configuracion",
    state: "configuracionCollapse",    
    tipo: "MENU",    
    views: [
      {
        path: "/usuarios",
        key: "usuarios",
        name: "Usuarios",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Usuarios,
        layout: "/admin",
        tipo: "SUBMENU",
      },
      {
        path: "/roles",
        key: "roles",
        name: "Roles",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Roles,
        layout: "/admin",
        tipo: "SUBMENU",
      },
      {
        path: "/objetos",
        key: "objetos",
        name: "Objetos",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Objetos,
        layout: "/admin",
        tipo: "SUBMENU",
      },
      {
        path: "/querys",
        key: "querys",
        name: "Querys",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Querys,
        layout: "/admin",
        tipo: "SUBMENU",
      },
      
    ],    
  },
  {
    path: "/login",
    key: "login",
    name: "Ingresar",
    icon: <DocumentIcon color='inherit' />,
    component: SignIn,
    layout: "/auth",
    tipo: "DFMENU",
  },
  {
    path: "/cambio",
    key: "cambio",
    name: "Cambio Contraseña",
    icon: <DocumentIcon color='inherit' />,
    component: CambioCla,
    layout: "/auth",
    tipo: "DFMENU",
  },
  //prueba/////////////////////////
  {
    path: "/salir",
    key: "salir",
    name: "Salir",
    icon: <RocketIcon color='inherit' />,
    component: SignUp,
    layout: "/auth",
    tipo: "DFMENU",
  },
];

async function shouldEnableRoute(name, tipo) {
  try {
    if (tipo === "DFMENU" ) {
      return true;
    } else {
      if (!localStorage.getItem('token') || !localStorage.getItem('usuario')) {
        return false;
      } else {
        const rest = await fetch(`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/objetosusuarios`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            usr_codigo: localStorage.getItem('usuario'),
            obj_etiqueta: name,
            obj_tipo: tipo,
          })
        });

        const resulte = await rest.json();
        return resulte.habilitada;
      }
    }
  } catch (error) {
    return false;
  }
}

// Obtén la lista de rutas habilitadas según la respuesta de la API
const enabledRoutes = await Promise.all(dashRoutes.map(async (route) => {
  if (route.views) {
    const enabledSubRoutes = await Promise.all(route.views.map(async (subRoute) => {
      const isEnabled = await shouldEnableRoute(subRoute.name, subRoute.tipo);
      return isEnabled ? subRoute : null;
    }));
    route.views = enabledSubRoutes.filter(subRoute => subRoute !== null);
  }

  const isEnabled = await shouldEnableRoute(route.name, route.tipo);
  return isEnabled ? route : null;
}));

const filteredRoutes = enabledRoutes.filter(route => route !== null);

export default filteredRoutes;
