import React, { useMemo, useEffect, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  palette: {}
});

const RolesUsuariosGrilla = ({ valorParaHijo, onEnviarDato }) => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [rowPinning, setRowPinning] = useState([]);
  
  useEffect(() => {

    const fetchData = async () => {

      try {
        const response = await fetch(`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/roles`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
          },
        });

        const json = await response.json();
        if (json && json.length > 0) {
          setData(json);
          console.log(" valor desde el padre",valorParaHijo);
          const seleccion = await fetch(`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/usuariosxroles/` + valorParaHijo, {
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('token'),
            },
          });
          const seleccionjson = await seleccion.json();
          // Obtener los valores para rowPinning y rowSelection desde la API
          const pinningValues = seleccionjson.map((item) => item.rol_codigo);
          const selectionValues = seleccionjson.reduce((acc, item) => {
            acc[item.rol_codigo] = true;
            return acc;
          }, {});
          setRowPinning('{top: [' + pinningValues + ']}');
          setRowSelection(selectionValues);
          //console.log("pinningValues", '{top :[' + pinningValues + ']}');

        } else {
          console.error('La propiedad "data" en el objeto JSON es undefined o vacía.');
        }

      } catch (error) {
        setIsError(true);
        console.error(error);
      }
    };

    fetchData();

  }, [valorParaHijo]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'rol_codigo',
        header: 'Codigo',
      },
      {
        accessorKey: 'rol_descripcion',
        header: 'Descripcion',
      },      
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId: (row) => row.rol_codigo,
    initialState: {
      //showColumnFilters: true,
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiTableContainerProps: {
      sx: {
        maxHeight: '400px',
      },
    },
    muiTableBodyRowProps: ({ row, table }) => {
      const { density } = table.getState();
      return {
        sx: {
          //Set a fixed height for pinned rows
          height: row.getIsPinned()
            ? `${
            //Default mrt row height estimates. Adjust as needed.
            density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69
            }px`
            : undefined,
        },
      };
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error al cargar datos',
      }
      : undefined,
    //selection: selectedRows,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      rowPinning,
      showAlertBanner: isError,
    },
  });
// como cargar en la base
  useEffect(() => {
   //console.log("rowSelection", rowSelection); //read your managed row selection state       
   onEnviarDato(rowSelection);
  }, [rowSelection]);

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

export default RolesUsuariosGrilla;
