
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,  
  Text,
  useColorModeValue,  
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React from "react";
import { useHistory } from 'react-router-dom';


function SignUp() {

  const history = useHistory();
  localStorage.removeItem('token');  
  localStorage.removeItem('usuario');
  localStorage.removeItem('nombre');  
  localStorage.removeItem('cambio');
  localStorage.clear;  

  const bgForm = useColorModeValue("white", "navy.800");

  const handleLogin = async () => {
    history.push('/signin');
  };

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        maxH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        maxW={{ md: "calc(100vw - 50px)" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgImage={BgSignUp}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
        borderRadius={{ base: "0px", md: "20px" }}>
        <Box w='100vw' h='100vh' bg='blue.500' opacity='0.8'></Box>
      </Box>
      <Flex
        direction='column'
        textAlign='center'
        justifyContent='center'
        align='center'
        mt='125px'
        mb='30px'>
        <Text fontSize='4xl' color='white' fontWeight='bold'>
          Salio Exitosamente
        </Text>        
      </Flex>

      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "100px" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}>                   
          <FormControl>                        
            <Button
              fontSize='10px'
              variant='dark'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'
              onClick={handleLogin}
              >
              Acceder
            </Button>
          </FormControl>      
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
