const formatter = new Intl.DateTimeFormat('es-AR', {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone: 'America/Argentina/Buenos_Aires',
});
export default function timeFormat(value) {
    try {
        const fecha = new Date(value);
        return formatter.format(fecha);
    } catch (error) {
        console.log(fecha);
        return '';
    }
    
    
    return fecha;
}