import React, { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { IconButton, Input, InputAdornment } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';

const PeriodoFilter = ({column, header, table, filters}) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    // if (filters.some(filter => filter.id === column.id)) {
    //   return;
    // }
    const elem = filters.find(filter => filter.id === column.id);
    if (elem && elem.value !== inputValue) {
      setInputValue(elem.value);
    }
    
    //setInputValue(column.getFilterValue() || '');
  },[filters]);
  const debouncedSetFilter = useMemo(
      () => debounce((value) => {
          if (value === '' || /^\d{4}-(0[1-9]|1[0-2])$/.test(value)) {
              column.setFilterValue(value);
          }
      }, 500),
      [column]
  );

  const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);
      debouncedSetFilter(value);
  };

  const handleClearInput = () => {
      setInputValue('');
      column.setFilterValue('');
  };
  return (
      <Input
          id="standard-periodo-filter"
          type={'text'}
          placeholder='Periodo'
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
          endAdornment={
          <InputAdornment position='end'>
              <IconButton
                  size="small"
                  disabled={!inputValue}
                  aria-label="toggle password visibility"
                  onClick={handleClearInput}
              >
              <ClearIcon fontSize="small" />
              </IconButton>
          </InputAdornment>
          }
      />
  );
};
export default PeriodoFilter;