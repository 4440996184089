// Chakra imports
import {
  Flex,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import { mkConfig, generateCsv, download } from 'export-to-csv';

//////////nuevos
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DataLabel from "views/Cards/DataLabel";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { jsPDF } from 'jspdf';
import jsonToTable from 'json-to-table';

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import GrillaEffect from "views/Grillas/GrillaEffect";
import dayjs from "dayjs";
import 'dayjs/locale/es';
registerLocale("es", es); // register it with the name you want


const theme = createTheme({
  palette: {}
});

function FacturacionBatch() {
  
  ///exporta csv
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const GenerarCsv = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/21`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();
      // console.log(" get consulta ", resulte);          
      // Convierte la respuesta a formato JSON
      const csv = generateCsv(csvConfig)(resulte);
      download(csvConfig)(csv);

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };

  ///exporta pdf 
  const GenerarPdf = async () => {

    try {
      // Realiza la solicitud a la API       
      const baseURL = `http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysext/21`;

      const rest = await fetch(baseURL,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token'),
          },
          body: JSON.stringify({
            fdesde: new Date(startDate).toLocaleDateString(),
            fhasta: new Date(endDate).toLocaleDateString()
          })
        });

      const resulte = await rest.json();

      const tabled = jsonToTable(resulte);
      let nombresCampos = tabled[0];
      const doc = new jsPDF();

      doc.autoTable({
        head: [nombresCampos],
        body: tabled,
        margin: { top: 10 },
        styles: {
          minCellHeight: 4,
          halign: "left",
          valign: "center",
          fontSize: 4,
        },
      });
      doc.save('pdf-cobranzas.pdf');

    } catch (error) {
      console.error(`Error al  generar csv : ${error.message}`);
      throw error;
    }

  };
  const defaultFecha = [
    {id: 'periodo_vto', value: dayjs().subtract(1, 'month').format('YYYY-MM'), col_ori: 'a.FactFec'}
  ];
  const [data, setData] = useState([]);
  const [dataGrilla2, setDataGrilla2] = useState([]);
  const [dataGrilla3, setDataGrilla3] = useState([]);
  const [filters, setFilters] = useState([...defaultFecha]);
  const [filters2, setFilters2] = useState([...defaultFecha]);
  
  const url = `http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/querysextgrilla/`;

  const colsGrilla1Hidden = {
    asociado: false,
    suministro: false,
    sum_numero: false,
    comprobante: false,
    kwh_donado: false,
    año_vencimiento: false,
    mes_vencimiento: false,
    area: false,
    ruta: false,
    tipo_cpte: false,
    clase_cpt: false,
    tarifa: false,
    barrio: false,
    cancelado: false,
    grupo_cobranza: false,
    grupo_organismos: false,
    solo_deuda: false,
    avance_facturacion: false,
    vencimiento: false,
    estado: false,
    etapa: false,
    tipo_socio: false,
    fecha_lectura: false,
    grupo_impresion: false,
    socio: false,
    documento_tipo: false,
    documento_numero: false,
    consorcio: false,
    tarifa_r: false,
    factura_electronica: false,
    actividad: false
  }

  const columnNames = {
    fact_fecha: 'Fecha Cbte.',
    asociado: 'Asociado',
    suministro: 'Suministro',
    sum_numero: 'Sum. Nro.',
    comprobante: 'Comprobante',
    importe: 'Importe',
    kw_hora: 'Kw Hora',
    kwh_donado: 'Kw/H Donado',
    año_vencimiento: 'Año Vto.',
    mes_vencimiento: 'Mes Vto.',
    area: 'Area',
    ruta: 'Ruta',
    tipo_cpte: 'Tipo Cbte.',
    clase_cpt: 'Clase CPT.',
    tarifa: 'Tarifa',
    barrio: 'Barrio',
    cancelado: 'Cancelado',
    grupo_cobranza: 'Grupo Cobranza',
    grupo_organismos: 'Grupo Organismos',
    periodo_vto: 'Pdo. Vto.',
    solo_deuda: 'Solo Deuda',
    avance_facturacion: 'Avance Facturacion',
    vencimiento: 'Vencimiento',
    periodo_consumo: 'Pdo. Consumo',
    estado: 'Estado',
    etapa: 'Etapa',
    tipo_socio: 'Tipo Socio',
    fecha_lectura: 'Fecha Lect.',
    grupo_impresion: 'Grupo Impresion',
    socio: 'Socio',
    documento_tipo: 'Doc. Tipo',
    documento_numero: 'Doc. Numero',
    consorcio: 'Consorcio',
    tarifa_r: 'Tarifa R.',
    factura_electronica: 'Fact. Elect.',
    actividad: 'Actividad'
  }

  const columnNames2 = {
    concepto: 'Concepto',
    importe: 'Importe',
    periodo_consumo: 'Pdo. Consumo',
    periodo_vto: 'Pdo. Vto.'
  }

  const columnNames3 = {
    periodo_vto: {caption: 'Periodo Vto.'},
    tarifa: {caption: 'Tarifa'},
    importe: {caption: 'Importe', enableColumnFilter: false},
    kw_hora: {caption: 'KW Hora', enableColumnFilter: false},
    c_comprobantes: {caption: 'Cant. Comprobantes', enableColumnFilter: false},
  }
  const topToolbar = function(table) {
    return <Box>
      <DatePicker
          label="Desde"
          value={filters.find(i => i.id == 'periodo_vto').value}
          onChange={(fecha) => {
            let items = [...filters];
            const x = items.findIndex(i => i.id == 'periodo_vto')
            console.log(items[x]);
            let item = {...items[x], value: [fecha, items[x].value[1]]};
            items[x] = item;
            table.table.setColumnFilters(items)
          }}
      />
      <DatePicker
          label="Hasta"
          value={filters.find(i => i.id == 'periodo_vto').value}
          onChange={(fecha) => {
            let items = [...filters];
            console.log('items aqui');
            const x = items.findIndex(i => i.id == 'fact_fecha')
            console.log(fecha);
            let item = {...items[x], value: [items[x].value[0],fecha]};
            items[x] = item;
            table.table.setColumnFilters(items)
          }}
      />
    </Box>
  }
  //////////////
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardBody size='sm'>
          <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='22px'>
            <GridItem colSpan={2}>
              <Card minH='125px'>
                <CardBody px='2px'>
                  <Grid templateColumns={{ sm: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap='5px'>
                    <DataLabel url={url+'?id='+22} filters={filters} columns={columnNames} label={"TOTAL FACTURADO"}/>
                    <DataLabel url={url+'?id='+23} filters={filters} columns={columnNames} label={"TOTAL KWH"}/>
                    <DataLabel url={url+'?id='+24} filters={filters} columns={columnNames} label={"TOTAL SUMINISTROS"} isMoney={false} />
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={1}>
              <Card minH='125px'>
                <Flex direction='column'>
                  <Flex
                    flexDirection='row'
                    align='center'
                    justify='center'
                    w='100%'
                    mb='25px'>
                    <Flex align='center'>
                      <Button                      
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='green'
                        onClick={GenerarCsv}
                      >
                        Exportar CSV
                      </Button>
                    </Flex>
                    <Flex align='center'>
                      <Button
                        fontSize='13px'
                        fontWeight='bold'
                        w='100%'
                        h='45'
                        variant='solid'
                        //colorScheme='red'
                        onClick={GenerarPdf}
                      >
                        Exportar PDF
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </GridItem>
          </Grid>
          <Card p='16px' my='5px'>
            <CardBody >

              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Comprobantes</Tab>
                  <Tab>Conceptos</Tab>
                  <Tab>Tarifas</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect 
                          data={data} updateData={setData} 
                          filters={filters} updateFilters={setFilters}
                          hiddenColumns={colsGrilla1Hidden}
                          colsHeaders={columnNames}
                          idRemoto={21}/>
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect 
                          data={dataGrilla2} updateData={setDataGrilla2} 
                          filters={filters} updateFilters={setFilters}
                          colsHeaders={columnNames2}
                          idRemoto={41}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                  <TabPanel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <ThemeProvider theme={theme}>
                        <GrillaEffect 
                          data={dataGrilla3} updateData={setDataGrilla3} 
                          filters={filters} updateFilters={setFilters}
                          colsHeaders={columnNames3}
                          idRemoto={25}/>
                      </ThemeProvider>
                    </LocalizationProvider>
                  </TabPanel>
                </TabPanels>
              </Tabs>

            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Flex>

  );
}

export default FacturacionBatch;