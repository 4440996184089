// Chakra imports
import {
  Flex,
  Text,
  Divider,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React from "react";
import TablaObjetos from "views/Grillas/ObjetosGrilla";

function Objetos() {
  const textColor = useColorModeValue("gray.700", "white");  

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Objetos del Sistema
          </Text>
          <Divider />
        </CardHeader>        
        <CardBody>
        <TablaObjetos/>
        </CardBody>
      </Card>      
    </Flex>
  );
}

export default Objetos;
