import { useMemo, useState,useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'; //note: this is TanStack React Query V5
const theme = createTheme({
  palette: {}
});

// type User = {
//   id: string;
//   firstName: string;
//   lastName: string;
//   email: string;
//   state: string;
//   managerId: string | null; //row's parent row id
//   subordinateIds: string[]; //or some type of boolean that indicates that there are sub-rows
// };
const columnNames = {
  id: 'Codigo',  
  //no hace falta poner los nombre de la cabecera   
};

var codigo = '';

const GrillaDesplegable = ({ que_codigo }) => {
  codigo = que_codigo;

  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [expanded, setExpanded] = useState({}); //Record<string, boolean> | true

  //which rows have sub-rows expanded and need their direct sub-rows to be included in the API call
  const expandedRowIds = useMemo(
    () =>
      expanded === true
        ? 'all'
        : Object.entries(expanded)
          .filter(([_managerId, isExpanded]) => isExpanded)
          .map(([managerId]) => managerId),
    [expanded],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading,
  } = useFetchUsers({
    pagination,
    sorting,
    expandedRowIds,
  });

  //get data for root rows only (top of the tree data)
  const rootData = useMemo(() => data.filter((r) => !r.managerId), [data]);

  //create columns from data
  const columns = useMemo(
    () =>
      data.length
        ? Object.keys(data[0]).map((columnId) => ({
          header: columnNames[columnId] ?? columnId,
          accessorKey: columnId,
          id: columnId,
          //columnVisibility: columnId === 'managerId' || columnId === 'subordinateIds' ? false : true,
        }))
        : [],
    [data],
  ); 

  const table = useMaterialReactTable({
    columns,
    data: rootData,

    initialState: { columnVisibility: { managerId: false ,subordinateIds: false, } },

    enableExpanding: true, //enable expanding column
    enableFilters: false,
    //tell MRT which rows have additional sub-rows that can be fetched
    getRowCanExpand: (row) => !!row.original.subordinateIds.length, //just some type of boolean
    //identify rows by the user's id
    getRowId: (row) => row.id,
    //if data is delivered in a flat array, MRT can convert it to a tree structure
    //though it's usually better if the API can construct the nested structure before this point
    getSubRows: (row) => data.filter((r) => r.managerId === row.id), //parse flat array into tree structure
    // paginateExpandedRows: false, //the back-end in this example is acting as if this option is false
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error al cargar datos',
      }
      : undefined,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: meta?.totalRowCount ?? 0,
    state: {
      expanded,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const ExampleWithReactQueryProvider = ({ que_codigo }) => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <GrillaDesplegable que_codigo={que_codigo} />
    </QueryClientProvider>
  </ThemeProvider>
);

export default ExampleWithReactQueryProvider;

//fetch user hook
const useFetchUsers = ({ pagination, sorting, expandedRowIds }) => {
  return useQuery({
    queryKey: [
      'users', //give a unique key for this query
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
      expandedRowIds,
    ],
    queryFn: async () => {

      try {
        // Realiza la solicitud a la API       
        const baseURL = `http://localhost:3001/api/v1/querysextgrillaexp/`;
        // Objeto con los parámetros que deseas enviar                   
        const queryParams = {
          id: codigo,
          page: pagination.pageIndex,
          size: pagination.pageSize,
          sorting: JSON.stringify(sorting ?? []),
          expanded: expandedRowIds === 'all' ? 'all' : JSON.stringify(expandedRowIds ?? [])
        };
        // Construye la URL completa con los parámetros
        const urlWithParams = new URL(baseURL);
        // console.log("urlWithParams xxxx",urlWithParams);
        Object.keys(queryParams).forEach(key => urlWithParams.searchParams.append(key, queryParams[key]));
        //console.log("urlWithParams yyyyyyy ",urlWithParams);
        // Realiza la solicitud fetch con la URL completa que incluye los parámetros                        

        const otherParams = {
          fdesde: new Date().toLocaleDateString(),
          fhasta: new Date().toLocaleDateString(),
          // ... Puede agregar más parámetros según sea necesario
        };

        const rest = await fetch(urlWithParams,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem('token'),
            },
            body: JSON.stringify(otherParams)
          });

        const resulte = await rest.json();
        return resulte;
      } catch (error) {
        console.error(`Error en la solicitud: ${error.message}`);
        throw error;
      }
      
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });
};
