import { useMemo } from "react";
import PeriodoFilter from "./PeriodoFilter";
import { Box } from "@mui/material";
export default function gridColumns(data, columnNames = [], filters) {
    return useMemo(
      () => data?.length
        ? Object.keys(data[0]).map(function (columnId) {
          let caption;
          let enableColumnFilter = true;
          if (typeof columnNames[columnId] === 'object') {
            caption = columnNames[columnId].caption;
            enableColumnFilter = columnNames[columnId].enableColumnFilter;
          } else if (typeof columnNames[columnId] === 'string') {
            caption = columnNames[columnId];
          } else {
            caption = columnId;
          }
          if (columnId.toLocaleLowerCase().includes('fecha')) {
            return {
              accessorFn: function (row) { return new Date(row[columnId]); },
              id: columnId,
              header: caption,
              enableColumnFilter: enableColumnFilter,
              filterVariant: 'date-range',
              muiFilterTextFieldProps: {
                sx: {
                  minWidth: '160px',
                }
              },
              
              Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            };
          }
          if (columnId.toLocaleLowerCase().includes('periodo')) {
            return {
              id: columnId,
              accessorKey: columnId,
              header: caption,
              enableColumnFilter: enableColumnFilter,
              Filter: ({ column, header, table }) => (
                <PeriodoFilter column={column} header={header} table={table} filters={filters} />
              ),
              Cell: ({ cell }) => cell.getValue(),
            };
          }
          if (columnId.toLocaleLowerCase().includes('importe')) {
            return {
              header: caption,
              accessorKey: columnId,
              enableColumnFilter: enableColumnFilter,
              id: columnId,
              // Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-AR', {
              //   style: 'currency',
              //   currency: 'ARS',
              //   minimumFractionDigits: 2,
              //   maximumFractionDigits: 2,
              // }),
              Cell: ({ cell }) => (
                <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() < 50_000
                      ? theme.palette.error.dark
                      : cell.getValue() >= 50_000 && cell.getValue() < 75_000
                        ? theme.palette.warning.dark
                        : theme.palette.success.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  maxWidth: '9ch',
                  p: '0.25rem',
                })}
              >
                {cell.getValue()?.toLocaleString?.('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              </Box>
              )
            };
          }
          return {
            header: caption,
            enableColumnFilter: enableColumnFilter,
            accessorKey: columnId,
            id: columnId,
          };
        })
        : [],
      [data]
    );
}


//lo dejo aca por si las dudas era un filtro de rango de fechas
// Filter: ({ column, rangeFilterIndex }) => (
//   <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         onChange={newValue => {
//           column.setFilterValue(oldValues => {
//             if (typeof rangeFilterIndex === 'undefined') {
//               return newValue;
//             }
//             const newValues = oldValues ?? ['', ''];
//             newValues[rangeFilterIndex] = newValue;
//             return newValues;
//           });
//         }}
//         sx={}
//         value={
//           typeof rangeFilterIndex !== 'undefined'
//             ? column.getFilterValue()?.[rangeFilterIndex] || [null, null] // Index into the filter value
//             : column.getFilterValue() || null
//         }
//       />
//   </LocalizationProvider>
// ),