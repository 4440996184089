import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Spinner, Stat, StatLabel, StatNumber, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import moneyFormat from "helpers/moneyFormat";
import { Box } from "@mui/material";

const DataLabel = ({
    url,
    filters,
    label,
    isMoney = true,
    countDateColumn = null,
    columns = []
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [result, setResult] = useState();
    const textColor = useColorModeValue("white", "white");
    useEffect(() => {
        const fetchData = async () => {
            if (!result) {
                setIsLoading(true);
            }
            if (countDateColumn) {
                if (countDateColumn.includes('periodo')) {
                    const periodo = filters.find(i => i.id == countDateColumn);
                    if (!periodo) {
                        setIsError(true);
                        return;
                    }
                    const [year, month] = periodo.value.split('-').map(Number);
                    const daysInMonth = new Date(year, month, 0).getDate();
                    setResult(daysInMonth);
                    setIsLoading(false);
                    return;
                }
                const fechas = filters.find(i => i.id == countDateColumn);
                if(!fechas || fechas.value.length != 2)
                {
                    setIsError(true);
                    return;
                }
                const diff = fechas.value[1].diff(fechas.value[0],'day',true);
                setResult(Math.floor(diff)-1);
                setIsLoading(false);
                return;
            }
            try {
                let currentFilters = [...filters];
                currentFilters = currentFilters.filter(filter => 
                    Object.keys(columns).some(columnKey => columnKey === filter.id)
                );
                const response = await fetch(url + '&' + new URLSearchParams({
                    filters: JSON.stringify(currentFilters ?? [])
                }),
                    {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": localStorage.getItem('token'),
                        }
                    });
                const json = await response.json();
                setResult(json.data[0].total || 0);
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
            setIsError(false);
            setIsLoading(false);
        };
        fetchData();
    }, [
        filters
    ]);
    return <Flex align='center' mb='9px'>
        {isError ?
            <WarningTwoIcon w={8} h={8} color="blue.500" /> :
            <Stat me='auto'>
                <StatLabel
                    fontSize='xs'
                    color='gray.400'
                    fontWeight='bold'
                    textTransform='uppercase'>
                    {label}
                </StatLabel>
                <Flex>
                    {isLoading ?
                        <Spinner color='blue.500' /> :
                        <Box
                            component="span"
                            sx={(theme) => ({
                                backgroundColor: '#3182ce',
                                borderRadius: '0.25rem',
                                color: '#fff',
                                p: '0.25rem',
                            })}
                        >
                            <StatNumber fontSize='sm' color={textColor}>{isMoney ? moneyFormat(result) : result}</StatNumber>
                        </Box>
                        
                    }
                </Flex>
            </Stat>
        }
    </Flex>
}

export default DataLabel;