import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const history = useHistory();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Verifica el estado de autenticación al cargar la página
        checkAuthentication();
        history.listen(() => {
            checkAuthentication();
                
        })
    }, []);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    };

    const isAuthenticated = () => {
        return !!user;
    };

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const checkAuthentication = () => {
        const token = parseJwt(localStorage.getItem("token"));
        if (token) {
            if (token.exp * 1000 < Date.now()) {
                logout();
                history.push('/auth/login');
            }
            else {
                setUser(token);
            }
        }
        else if(pathname !== '/auth/login')
        {
            logout();
            //history.push('/auth/login');
            if (history.location.pathname !== '/auth/login') {
                history.push('/auth/login');
            }    
        }
        setLoading(false);
    };

    const value = {
        user,
        login,
        logout,
        isAuthenticated,
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
