import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme.js";
import { AuthProvider } from './AuthProvider.js';

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <HashRouter>
    <AuthProvider>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from={`/`} to="/admin/dashboard" />
      </Switch>
      </AuthProvider>
    </HashRouter>
  </ChakraProvider>
);
