import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  SimpleGrid,
} from "@chakra-ui/react"
//import React from "react";
import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import axios from 'axios';
import { useHistory } from 'react-router-dom';



function CambioClave() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");

  const [passwordact, setPasswordact] = useState('');
  const [passwordnew, setPasswordnew] = useState('');
  const [passwordnewr, setPasswordnewr] = useState('');
  const [error, setError] = useState('');

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const history = useHistory();

  const CambioContrasena = async () => {
    try {

      if (passwordnew === passwordnewr && passwordnewr != passwordact) {
        //verificamos si la contraseña actual es correcta
        let username = localStorage.getItem('usuario');
        let usernamedb = '';
        try {
          const response = await axios.post(`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/login`, {
            usr_codigo: username,
            usr_clave: passwordact,
          });
          usernamedb = response.data.usr_codigo;
        } catch (err) {
          usernamedb = '';
        }

        if (usernamedb === username) {
          //cambiamos la contraseña nueva
          const rest = await fetch(`http://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/v1/resetpass/` + username,
            {
              method: "put",
              headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('token'),
              },
              body: JSON.stringify({
                usr_clave: passwordnewr
              })
            })

          const resulte = await rest.json();

          if(localStorage.getItem('cambio') === 'S'){
             localStorage.setItem('cambio', 'N');
          }          
          history.push('/admin');
          window.location.reload();
        }
        else {
          setError('La contraseña actual es incorrecta');
          setShowErrorAlert(true);
        }
      }
      else {
        setError('Verifique el ingreso nuevas contraseñas');
        setShowErrorAlert(true);
      }

    } catch (err) {
      // Manejar el error de autenticación      
      console.error('Error de autenticación:', err.message);
      setError('Credenciales inválidas');
      setShowErrorAlert(true);
    }
  };

  const CancelaCambio = async () => {
    if(localStorage.getItem('cambio') === 'S'){
      setError('Debe actualizar contraseña');
      setShowErrorAlert(true);
    }
    else{
      history.push('/admin');
    }
    
  };


  return (
    <Flex position='relative' mb='40px'>
      <Flex
        minH={{ md: "1000px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}>

        <Flex
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Cambio Contraseña
            </Text>

            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Contraseña Actual
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Contraseña actual'
                value={passwordact} onChange={(e) => setPasswordact(e.target.value)}
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Nueva Contraseña
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Nueva contraseña'
                value={passwordnew} onChange={(e) => setPasswordnew(e.target.value)}
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Repetir Contraseña
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Repetir contraseña'
                value={passwordnewr} onChange={(e) => setPasswordnewr(e.target.value)}
                mb='24px'
                size='lg'
              />
              <SimpleGrid columns={{ sm: 2, md: 2, xl: 2 }} spacing='24px' mb='20px'>
                <Button
                  fontSize='10px'
                  variant='dark'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  onClick={CambioContrasena}
                >
                  Confirmar
                </Button>
                <Button
                  fontSize='10px'
                  variant='dark'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  onClick={CancelaCambio}
                >
                  Cancelar
                </Button>
              </SimpleGrid>

              <div>
                {showErrorAlert && (
                  <Alert status="error" onClose={() => setShowErrorAlert(false)}>
                    <AlertIcon />
                    <AlertTitle mr={2}>¡Error!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
              </div>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
          bgImage={signInImage}>
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='blue.500'
            opacity='0.8'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default CambioClave;
